define("ember-table/components/ember-tr/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-table/-private/utils/element", "ember-table/components/ember-tr/template", "ember-table/-private/collapse-tree"], function (_exports, _component, _object, _computed, _element, _template, _collapseTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The table row component. This component manages row level concerns, and yields
    an API object that contains the cell component, the cell/column/row values,
    and the cell/column/row meta objects. It is used in both the header and the
    body, mirroring the structure of native HTML tables.
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{this.columns}} as |h|>
        <h.row as |r|>
          <r.cell>
        </h.row>
      </t.head>
  
      <t.body @rows={{this.rows}} as |b|>
        <b.row as |r|>
          <r.cell>
        </b.row>
      </t.body>
    </EmberTable>
    ```
  
    @yield {object} row - the API object yielded by the table row
    @yield {Component} row.cell - The table cell contextual component
  
    @yield {any} row.cellValue - The value for the currently yielded cell
    @yield {object} row.cellMeta - The meta for the currently yielded cell
  
    @yield {object} row.columnValue - The value for the currently yielded column
    @yield {object} row.columnMeta - The meta for the currently yielded column
  
    @yield {object} row.rowValue - The value for the currently yielded row
    @yield {object} row.rowMeta - The meta for the currently yielded row
  
    @class {{ember-tr}}
    @public
  */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'tr',
    classNames: ['et-tr'],
    classNameBindings: ['isSelected', 'isGroupSelected', 'isSelectable'],

    /**
      The API object passed in by the table body, header, or footer
      @argument api
      @required
      @type object
    */
    api: null,

    /**
      Action sent when the user clicks this element
      @argument onClick
      @type Action?
    */
    onClick: null,

    /**
      Action sent when the user double clicks this element
      @argument onDoubleClick
      @type Action?
    */
    onDoubleClick: null,
    rowValue: (0, _computed.readOnly)('api.rowValue'),
    rowMeta: (0, _computed.readOnly)('api.rowMeta'),
    cells: (0, _computed.readOnly)('api.cells'),
    rowSelectionMode: (0, _computed.readOnly)('api.rowSelectionMode'),
    rowToggleMode: (0, _computed.readOnly)('api.rowToggleMode'),
    isHeader: (0, _computed.readOnly)('api.isHeader'),
    isSelected: (0, _computed.readOnly)('rowMeta.isSelected'),
    isGroupSelected: (0, _computed.readOnly)('rowMeta.isGroupSelected'),
    isSelectable: (0, _object.computed)('rowSelectionMode', function () {
      var rowSelectionMode = this.get('rowSelectionMode');
      return rowSelectionMode === _collapseTree.SELECT_MODE.MULTIPLE || rowSelectionMode === _collapseTree.SELECT_MODE.SINGLE;
    }),
    click: function click(event) {
      var rowSelectionMode = this.get('rowSelectionMode');
      var inputParent = (0, _element.closest)(event.target, 'input, button, label, a, select');

      if (!inputParent) {
        var rowMeta = this.get('rowMeta');

        if (rowMeta && rowSelectionMode === _collapseTree.SELECT_MODE.MULTIPLE) {
          var toggle = event.ctrlKey || event.metaKey || this.get('rowToggleMode');
          var range = event.shiftKey;
          rowMeta.select({
            toggle: toggle,
            range: range
          });
        } else if (rowMeta && rowSelectionMode === _collapseTree.SELECT_MODE.SINGLE) {
          rowMeta.select({
            single: true
          });
        }
      }

      this.sendEventAction('onClick', event);
    },
    doubleClick: function doubleClick(event) {
      this.sendEventAction('onDoubleClick', event);
    },
    sendEventAction: function sendEventAction(action, event) {
      var rowValue = this.get('rowValue');
      var rowMeta = this.get('rowMeta');
      var closureAction = this[action];
      closureAction === null || closureAction === void 0 ? void 0 : closureAction({
        event: event,
        rowValue: rowValue,
        rowMeta: rowMeta
      });
    }
  });

  _exports.default = _default;
});