define("ember-cli-mirage/ember-data", ["exports", "require", "ember-get-config", "ember-cli-mirage/assert", "ember-cli-mirage/utils/ember-data", "ember-cli-mirage", "ember-cli-mirage/serializers/ember-data-serializer", "ember-cli-mirage/utils/inflector"], function (_exports, _require, _emberGetConfig, _assert, _emberData, _emberCliMirage, _emberDataSerializer, _inflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyEmberDataSerializers = applyEmberDataSerializers;
  _exports.discoverEmberDataModels = discoverEmberDataModels;
  _exports.getDsModels = getDsModels;
  _exports.getDsSerializers = getDsSerializers;
  _exports.modelFor = modelFor;

  /* global requirejs */
  var modulePrefix = _emberGetConfig.default.modulePrefix,
      podModulePrefix = _emberGetConfig.default.podModulePrefix; // Caches

  var DsModels, Models;
  var DsSerializers, Serializers;
  /**
   * Get all ember data models under the app's namespaces
   *
   * @method getDsModels
   * @private
   * @hide
   * @return {Object} models
   */

  function getDsModels() {
    if (DsModels) {
      return DsModels;
    }

    var moduleMap = requirejs.entries;
    var classicModelMatchRegex = new RegExp("^".concat(modulePrefix, "/models/(.*)$"), 'i');
    var podModelMatchRegex = new RegExp("^".concat(podModulePrefix || modulePrefix, "/(.*)/model$"), 'i');
    DsModels = {};

    if (!_emberData.hasEmberData) {
      return DsModels;
    }

    Object.keys(moduleMap).forEach(function (path) {
      var matches = path.match(classicModelMatchRegex) || path.match(podModelMatchRegex);

      if (matches && matches[1]) {
        var modelName = matches[1];
        var model = (0, _require.default)(path, null, null, true).default;

        if ((0, _emberData.isDsModel)(model)) {
          DsModels[modelName] = model;
        }
      }
    });
    return DsModels;
  }
  /**
   * Get all mirage models for each of the ember-data models
   *
   * @method discoverEmberDataModels
   * @return {Object} models
   */


  function discoverEmberDataModels() {
    if (Models) {
      return Models;
    }

    var emberDataModels = getDsModels();
    Models = {};
    Object.keys(emberDataModels).forEach(function (modelName) {
      var model = emberDataModels[modelName];
      var attrs = {};
      model.eachRelationship(function (name, r) {
        if (r.kind === 'belongsTo') {
          attrs[name] = (0, _emberCliMirage.belongsTo)(r.type, r.options);
        } else if (r.kind === 'hasMany') {
          attrs[name] = (0, _emberCliMirage.hasMany)(r.type, r.options);
        }
      });
      Models[modelName] = _emberCliMirage.Model.extend(attrs);
    });
    return Models;
  }
  /**
   * A lookup method for an autogenerated model
   *
   * @method modelFor
   * @private
   * @param  {String} name
   * @return {Model}
   * @hide
   */


  function modelFor(name) {
    var models = discoverEmberDataModels();
    (0, _assert.default)(!!models[name], "Model of type '".concat(name, "' does not exist."));
    return models[name];
  }
  /**
   * Get all ember data serializers under the app's namespaces
   *
   * @method getDsSerializers
   * @private
   * @hide
   * @return {Object} serializers
   */


  function getDsSerializers() {
    if (DsSerializers) {
      return DsSerializers;
    }

    var moduleMap = requirejs.entries;
    var classicSerializerMatchRegex = new RegExp("^".concat(modulePrefix, "/serializers/(.*)$"), 'i');
    var podSerializerMatchRegex = new RegExp("^".concat(podModulePrefix || modulePrefix, "/(.*)/serializer$"), 'i');
    DsSerializers = {};

    if (!_emberData.hasEmberData) {
      return DsSerializers;
    }

    Object.keys(moduleMap).forEach(function (path) {
      var matches = path.match(classicSerializerMatchRegex) || path.match(podSerializerMatchRegex);

      if (matches && matches[1]) {
        var serializerName = matches[1];
        var serializer = (0, _require.default)(path, null, null, true).default; // in mirage, registering models takes care of camelize, serializers do not

        DsSerializers[(0, _inflector.camelize)(serializerName)] = serializer;
      }
    });
    return DsSerializers;
  }
  /**
   * Generate mirage serializers for each of the ember-data serializers
   * if a mirage serializer already exists, apply the ember-data transforms
   *
   * @method applyEmberDataSerializers
   * @return {Object} serializers
   */


  function applyEmberDataSerializers() {
    var mirageSerializers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (Serializers) {
      return Serializers;
    }

    var emberDataSerializers = getDsSerializers(); // Start off with the mirage serializers,
    // so if there are any mirage serializers with no ED counterpart, they are in the list

    Serializers = mirageSerializers;
    Object.keys(emberDataSerializers).forEach(function (serializerName) {
      var dsSerializer = emberDataSerializers[serializerName]; // Seems I have to create it to get access to some of the properties

      dsSerializer = dsSerializer.create ? dsSerializer.create() : new dsSerializer();
      var transforms;
      var primaryKey = dsSerializer.primaryKey;
      var attrs = dsSerializer.attrs;

      if (primaryKey || attrs) {
        var Serializer = mirageSerializers[serializerName] || mirageSerializers.application || _emberDataSerializer.default;

        if (attrs) {
          var serializer = Serializer.create ? Serializer.create() : new Serializer();
          transforms = serializer.transforms || {};
          Object.keys(attrs).forEach(function (key) {
            var transform = attrs[key];
            var serializerTransform = serializer.transforms ? serializer.transforms[key] : {};
            var resolvedTransform = typeof attrs[key] === 'string' ? {
              key: attrs[key]
            } : {
              key: attrs[key].key
            };

            if (transform.serialize !== undefined) {
              resolvedTransform.deserialize = transform.serialize;
            }

            if (transform.deserialize !== undefined) {
              resolvedTransform.serialize = transform.deserialize;
            }

            transforms[key] = Object.assign(resolvedTransform, serializerTransform);
          });
        }

        Serializers[serializerName] = Serializer.extend({
          primaryKey: primaryKey,
          transforms: transforms
        });
      }
    });
    return Serializers;
  }
});