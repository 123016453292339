define("ember-changeset/utils/is-object", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isObject;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  /**
   * Employ Ember strategies for isObject detection
   * @method isObject
   */
  function isObject(val) {
    return val !== null && _typeof(val) === 'object' && !(val instanceof Date || val instanceof RegExp) && !(0, _array.isArray)(val);
  }
});