define("liquid-fire/transitions/fly-to", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flyTo;

  function flyTo() {
    var _this = this;

    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (!this.newElement) {
      return _liquidFire.Promise.resolve();
    } else if (!this.oldElement) {
      this.newElement.style.visibility = '';
      return _liquidFire.Promise.resolve();
    }

    var oldOffset = getOffset(this.oldElement);
    var newOffset = getOffset(this.newElement);

    if (opts.movingSide === 'new') {
      var motion = {
        translateX: [0, oldOffset.left - newOffset.left],
        translateY: [0, oldOffset.top - newOffset.top],
        outerWidth: [this.newElement.offsetWidth, this.oldElement.offsetWidth],
        outerHeight: [this.newElement.offsetHeight, this.oldElement.offsetHeight]
      };
      this.oldElement.style.visibility = 'hidden';
      return (0, _liquidFire.animate)(this.newElement, motion, opts);
    } else {
      var _motion = {
        translateX: newOffset.left - oldOffset.left,
        translateY: newOffset.top - oldOffset.top,
        outerWidth: this.newElement.offsetWidth,
        outerHeight: this.newElement.offsetHeight
      };
      this.newElement.style.visibility = 'hidden';
      return (0, _liquidFire.animate)(this.oldElement, _motion, opts).then(function () {
        _this.newElement.style.visibility = '';
      });
    }
  }

  function getOffset(ele) {
    var _ele$getBoundingClien;

    var rect = (_ele$getBoundingClien = ele === null || ele === void 0 ? void 0 : ele.getBoundingClientRect()) !== null && _ele$getBoundingClien !== void 0 ? _ele$getBoundingClien : {
      top: 0,
      left: 0
    };
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX
    };
  }
});