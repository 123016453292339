define("ember-table/components/ember-table-simple-checkbox", ["exports", "@ember/component", "ember-table/-private/utils/default-to"], function (_exports, _component, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'input',
    attributeBindings: ['ariaLabel:aria-label', 'checked', 'disabled', 'indeterminate', 'type', 'value', 'dataTestSelectRow:data-test-select-row', 'dataTestCollapseRow:data-test-collapse-row'],
    ariaLabel: undefined,
    checked: (0, _defaultTo.default)(false),
    disabled: (0, _defaultTo.default)(false),
    indeterminate: (0, _defaultTo.default)(false),
    onChange: null,
    onClick: null,
    type: 'checkbox',
    value: null,
    click: function click(event) {
      var _this$onClick;

      (_this$onClick = this.onClick) === null || _this$onClick === void 0 ? void 0 : _this$onClick.call(this, event);
    },
    change: function change(event) {
      var _this$onChange;

      var checked = this.element.checked;
      var indeterminate = this.element.indeterminate;
      var value = this.get('value'); // Checked and indeterminate state have been changed, but that's not DDAU!
      // Reset the change, send the action and wait for it to be changed manually

      this.element.checked = this.get('checked');
      this.element.indeterminate = this.get('indeterminate');
      (_this$onChange = this.onChange) === null || _this$onChange === void 0 ? void 0 : _this$onChange.call(this, checked, {
        value: value,
        indeterminate: indeterminate
      }, event);
    }
  });

  _exports.default = _default;
});