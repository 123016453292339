define("ember-mapbox-gl/components/mapbox-gl-layer", ["exports", "@ember/polyfills", "@ember/application", "@ember/object", "@ember/object/internals", "@ember/object/computed", "@ember/component", "ember-mapbox-gl/templates/components/mapbox-gl-layer"], function (_exports, _polyfills, _application, _object, _internals, _computed, _component, _mapboxGlLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Adds a data source to the map.
    The API matches the mapbox [source docs](https://www.mapbox.com/mapbox-gl-js/api/#sources).
  
    Example:
    ```hbs
    {{#mapbox-gl as |map|}}
      {{#map.source options=(hash
        type='geojson'
        data=(hash
          type='FeatureCollection'
          features=(array
            (hash
              type='Feature'
              geometry=(hash
                type='Point'
                coordinates=(array -96.7969879 32.7766642)
              )
            )
          )
        )) as |source|}}
        {{source.layer layer=(hash
            type='circle'
            paint=(hash circle-color='#007cbf' circle-radius=10))}}
      {{/map.source}}
    {{/mapbox-gl}}
    ```
  
    @class MapboxGLSource
  */
  var _default = _component.default.extend({
    layout: _mapboxGlLayer.default,
    tagName: '',
    map: null,

    /**
      @argument layer
      @type {Object}
      @description
      A hash to pass on to the mapbox [layer](https://www.mapbox.com/mapbox-gl-js/style-spec/#layers).
    */
    layer: null,

    /**
      @argument before
      @type {String}
      @description
      The ID of an existing layer to insert the new layer before.
      If this argument is omitted, the layer will be appended to the end of the layers array.
    */
    before: null,

    /**
     * @property _sourceId
     * @private for use by mapbox-gl-source to pass in its sourceId
     */
    _sourceId: (0, _computed.reads)('layer.source'),

    /**
     * @property _layerId
     * @private
     */
    _layerId: (0, _object.computed)('layer.id', function () {
      var _this$layer$id, _this$layer;

      return (_this$layer$id = (_this$layer = this.layer) === null || _this$layer === void 0 ? void 0 : _this$layer.id) !== null && _this$layer$id !== void 0 ? _this$layer$id : (0, _internals.guidFor)(this);
    }).readOnly(),

    /**
     * @property _layerType
     * @private
     */
    _layerType: (0, _object.computed)('layer.type', function () {
      var _this$layer$type, _this$layer2;

      return (_this$layer$type = (_this$layer2 = this.layer) === null || _this$layer2 === void 0 ? void 0 : _this$layer2.type) !== null && _this$layer$type !== void 0 ? _this$layer$type : 'line';
    }).readOnly(),
    _envConfig: (0, _object.computed)('_layerType', function () {
      var _config$mapboxGl;

      var config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return ((_config$mapboxGl = config['mapbox-gl']) !== null && _config$mapboxGl !== void 0 ? _config$mapboxGl : {})[this._layerType];
    }).readOnly(),
    _layout: (0, _object.computed)('_envConfig.layout', 'layer.layout', function () {
      var _this$_envConfig, _this$layer3;

      return (0, _polyfills.assign)({}, (_this$_envConfig = this._envConfig) === null || _this$_envConfig === void 0 ? void 0 : _this$_envConfig.layout, (_this$layer3 = this.layer) === null || _this$layer3 === void 0 ? void 0 : _this$layer3.layout);
    }).readOnly(),
    _paint: (0, _object.computed)('_envConfig.paint', 'layer.paint', function () {
      var _this$_envConfig2, _this$layer4;

      return (0, _polyfills.assign)({}, (_this$_envConfig2 = this._envConfig) === null || _this$_envConfig2 === void 0 ? void 0 : _this$_envConfig2.paint, (_this$layer4 = this.layer) === null || _this$layer4 === void 0 ? void 0 : _this$layer4.paint);
    }).readOnly(),
    _layer: (0, _object.computed)('layer', '_layerId', '_layerType', '_sourceId', '_layout', '_paint', function () {
      // do this to pick up other properties like filter, re, metadata, source-layer, minzoom, maxzoom, etc
      return (0, _polyfills.assign)({}, this.layer, {
        id: this._layerId,
        type: this._layerType,
        source: this._sourceId,
        layout: this._layout,
        paint: this._paint
      });
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this.map.addLayer(this._layer, this.before);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _layer = this._layer;

      for (var k in _layer.layout) {
        this.map.setLayoutProperty(_layer.id, k, _layer.layout[k]);
      }

      for (var _k in _layer.paint) {
        this.map.setPaintProperty(_layer.id, _k, _layer.paint[_k]);
      }

      if ('filter' in _layer) {
        this.map.setFilter(_layer.id, _layer.filter);
      }

      this.map.setLayerZoomRange(_layer.id, _layer.minzoom, _layer.maxzoom);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.map.removeLayer(this._layerId);
    }
  });

  _exports.default = _default;
});