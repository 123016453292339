define('ember-infinite-scroll/components/infinite-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      get = Ember.get,
      on = Ember.on,
      run = Ember.run,
      set = Ember.set;

  var $window = $(window);
  var $document = $(document);

  var EPSILON = 150;

  exports.default = Ember.Component.extend({
    action: 'fetchMore',
    epsilon: EPSILON,
    isFetching: false,
    hasMore: null,
    content: null,
    scrollElement: null,
    debounce: null,

    setup: on('didInsertElement', function () {
      this.safeSet('$scrollElement', $window);
      this.safeSet('scrollEvent', 'scroll.' + this.elementId);

      if (typeof get(this, 'scrollElement') === 'string') {
        this.safeSet('$scrollElement', $(this.scrollElement + ':first'));
        this.safeSet('scrollEvent', 'scroll');
      }

      if (typeof get(this, 'debounce') !== 'boolean' && typeof get(this, 'debounce') !== 'number') {
        this.safeSet('debounce', false);
      }

      var scrollHandlerId = 'scrollHandler_' + Math.ceil(Math.random() * 100000);

      this.safeSet('scrollHandlerId', scrollHandlerId);
      get(this, '$scrollElement').on(get(this, 'scrollEvent'), run.bind(this, this.didScroll));
    }),

    teardown: on('willDestroyElement', function () {
      get(this, '$scrollElement').off(get(this, 'scrollEvent'));

      if (get(this, 'timeout')) {
        clearTimeout(this.get('timeout'));
      }
    }),

    didScroll: function didScroll() {
      if (!get(this, 'debounce')) {
        this.handleScroll();
      } else {
        clearTimeout(this.get('timeout'));

        var timer = typeof get(this, 'debounce') === 'number' ? get(this, 'debounce') : 250;
        this.safeSet('timeout', setTimeout(this.handleScroll.bind(this), timer));
      }
    },
    handleScroll: function handleScroll() {
      if (!get(this, 'isFetching') && get(this, 'hasMore') && this.isNearBottom()) {
        this.safeSet('isFetching', true);
        this.sendAction('action', run.bind(this, this.handleFetch));
      }
    },
    handleFetch: function handleFetch(promise) {
      var success = run.bind(this, this.fetchDidSucceed);
      var failure = run.bind(this, this.fetchDidFail);

      return promise.then(success, failure);
    },
    fetchDidSucceed: function fetchDidSucceed(response) {
      var content = get(this, 'content');
      var newContent = get(response, 'content') ? get(response, 'content') : response;

      this.safeSet('isFetching', false);
      if (content) {
        content.pushObjects(newContent);
      }
    },
    fetchDidFail: function fetchDidFail() {
      this.safeSet('isFetching', false);
    },
    isNearBottom: function isNearBottom() {
      if (this.$scrollElement === $window) {
        /** Keep our legacy functionality if we're listening to the window scroll event */
        var viewPortTop = $document.scrollTop();
        var bottomTop = $document.height() - $window.height();

        return viewPortTop && bottomTop - viewPortTop < get(this, 'epsilon');
      } else {
        /**
         * We're going to use the scroll element to calculate the height, if we're not using the default functionality
         * from: http://stackoverflow.com/questions/6271237/detecting-when-user-scrolls-to-bottom-of-div-with-jquery
         * retreived: 20150205
         */
        return get(this, '$scrollElement').scrollTop() + get(this, '$scrollElement').innerHeight() >= get(this, '$scrollElement')[0].scrollHeight - get(this, 'epsilon');
      }
    },
    safeSet: function safeSet(key, value) {
      if (!this.isDestroyed && !this.isDestroying) {
        set(this, key, value);
      }
    }
  });
});