define("ember-local-storage/mixins/adapters/import-export", ["exports", "@ember/debug", "@ember/object/mixin", "ember-local-storage/helpers/import-export"], function (_exports, _debug, _mixin, _importExport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    importData: function importData(store, content, options) {
      (true && !(false) && (0, _debug.deprecate)('Using the import-export mixin has been deprecated and will be removed in version 3.0.0', false, {
        for: 'ember-local-storage',
        id: 'ember-local-storage.mixins.adapters.import-export',
        since: '2.0.5',
        until: '3.0.0',
        url: 'https://github.com/funkensturm/ember-local-storage#deprecations'
      }));
      return (0, _importExport.importData)(store, content, options);
    },
    exportData: function exportData(store, types, options) {
      (true && !(false) && (0, _debug.deprecate)('Using the import-export mixin has been deprecated and will be removed in version 3.0.0', false, {
        for: 'ember-local-storage',
        id: 'ember-local-storage.mixins.adapters.import-export',
        since: '2.0.5',
        until: '3.0.0',
        url: 'https://github.com/funkensturm/ember-local-storage#deprecations'
      }));
      return (0, _importExport.exportData)(store, types, options);
    }
  });

  _exports.default = _default;
});