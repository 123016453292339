define("ember-table/-private/column-tree", ["exports", "@ember/object", "ember-table/-private/utils/observer", "@ember/array", "@ember/object/computed", "ember-raf-scheduler", "ember-table/-private/utils/array", "ember-table/-private/utils/sort", "@ember/utils", "ember-table/-private/utils/element", "ember-table/-private/utils/reorder-indicators", "ember-table/-private/utils/ember", "@ember/debug"], function (_exports, _object, _observer, _array, _computed, _emberRafScheduler, _array2, _sort, _utils, _element, _reorderIndicators, _ember, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WIDTH_CONSTRAINT = _exports.RESIZE_MODE = _exports.FILL_MODE = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var SCROLL_THRESHOLD = 50;
  var DEFAULT_COLUMN_WIDTH = 100;
  var DEFAULT_MIN_WIDTH = 50;
  var DEFAULT_MAX_WIDTH = Infinity;
  var LOOP_COUNT_GUARD = 500;
  var RESIZE_MODE = {
    STANDARD: 'standard',
    FLUID: 'fluid'
  };
  _exports.RESIZE_MODE = RESIZE_MODE;
  var FILL_MODE = {
    EQUAL_COLUMN: 'equal-column',
    FIRST_COLUMN: 'first-column',
    LAST_COLUMN: 'last-column',
    NTH_COLUMN: 'nth-column'
  };
  _exports.FILL_MODE = FILL_MODE;
  var WIDTH_CONSTRAINT = {
    NONE: 'none',
    EQ_CONTAINER: 'eq-container',
    EQ_CONTAINER_SLACK: 'eq-container-slack',
    GTE_CONTAINER: 'gte-container',
    GTE_CONTAINER_SLACK: 'gte-container-slack',
    LTE_CONTAINER: 'lte-container'
  };
  /**
   * Divides x into y pieces where all y pieces are rounded
   * and sum to x. Assumes x is already rounded.
   * Returns a list of the pieces.
   *
   * For example:
   * 10 / 3 => [4, 3, 3]
   * -11 / 2 => [-6, -5]
   */

  _exports.WIDTH_CONSTRAINT = WIDTH_CONSTRAINT;

  function divideRounded(x, n) {
    var neg = x < 0 === n < 0 ? 1 : -1;
    n = Math.abs(n);
    x = Math.abs(x);
    var z = Math.floor(x / n);
    var err = x - n * z;
    var result = Array(n);
    result.fill(neg * (z + 1), 0, err);
    result.fill(neg * z, err);
    return result;
  }

  var TableColumnMeta = _object.default.extend({
    // If no width is set on the column itself, we cache a temporary width on the
    // meta object. This is set to the default width.
    _width: DEFAULT_COLUMN_WIDTH,
    isLeaf: (0, _computed.readOnly)('_node.isLeaf'),
    isFixed: (0, _computed.readOnly)('_node.isFixed'),
    isSortable: (0, _computed.readOnly)('_node.isSortable'),
    isResizable: (0, _computed.readOnly)('_node.isResizable'),
    isReorderable: (0, _computed.readOnly)('_node.isReorderable'),
    isSlack: (0, _computed.readOnly)('_node.isSlack'),
    width: (0, _computed.readOnly)('_node.width'),
    offsetLeft: (0, _computed.readOnly)('_node.offsetLeft'),
    offsetRight: (0, _computed.readOnly)('_node.offsetRight'),
    rowSpan: (0, _object.computed)('isLeaf', '_node.{depth,tree.root.maxChildDepth}', function () {
      if (!this.get('isLeaf')) {
        return 1;
      }

      var maxDepth = this.get('_node.tree.root.maxChildDepth');
      var depth = this.get('_node.depth');
      return maxDepth - (depth - 1);
    }),
    columnSpan: (0, _object.computed)('isLeaf', '_node.leaves.length', function () {
      if (this.get('isLeaf')) {
        return 1;
      }

      return this.get('_node.leaves.length');
    }),
    index: (0, _object.computed)('isLeaf', '_node.offsetIndex', function () {
      if (this.get('isLeaf')) {
        return this.get('_node.offsetIndex');
      }
    }),
    isLastRendered: (0, _computed.readOnly)('_node.isLastRendered'),
    sortIndex: (0, _object.computed)('_node.{tree.sorts.[],column.valuePath}', function () {
      var valuePath = this.get('_node.column.valuePath');
      var sorts = this.get('_node.tree.sorts');
      var sortIndex = 0;

      for (var i = 0; i < (0, _object.get)(sorts, 'length'); i++) {
        var sorting = (0, _array2.objectAt)(sorts, i);

        if ((0, _object.get)(sorting, 'valuePath') === valuePath) {
          sortIndex = i + 1;
          break;
        }
      }

      return sortIndex;
    }),
    isSorted: (0, _computed.gt)('sortIndex', 0),
    isMultiSorted: (0, _computed.gt)('_node.tree.sorts.length', 1),
    isSortedAsc: (0, _object.computed)('_node.tree.sorts.[]', 'sortIndex', function () {
      var sortIndex = this.get('sortIndex');
      var sorts = this.get('_node.tree.sorts');
      return (0, _object.get)((0, _array2.objectAt)(sorts, sortIndex - 1), 'isAscending');
    })
  });
  /**
    Single node of a ColumnTree
  */


  var ColumnTreeNode = _object.default.extend({
    _subcolumnNodes: null,
    isSlack: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var tree = (0, _object.get)(this, 'tree');
      var parent = (0, _object.get)(this, 'parent');
      var column = (0, _object.get)(this, 'column');

      if (!parent) {
        this.isRoot = true;
      } else {
        var columnMetaCache = (0, _object.get)(tree, 'columnMetaCache');
        var meta = columnMetaCache.getOrCreate(column, TableColumnMeta);
        (0, _object.set)(meta, '_node', this);

        meta.registerElement = function () {
          return _this.registerElement.apply(_this, arguments);
        };

        meta.startResize = function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return tree.startResize.apply(tree, [_this].concat(args));
        };

        meta.updateResize = function () {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }

          return tree.updateResize.apply(tree, [_this].concat(args));
        };

        meta.endResize = function () {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }

          return tree.endResize.apply(tree, [_this].concat(args));
        };

        meta.startReorder = function () {
          for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
            args[_key4] = arguments[_key4];
          }

          return tree.startReorder.apply(tree, [_this].concat(args));
        };

        meta.updateReorder = function () {
          for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
            args[_key5] = arguments[_key5];
          }

          return tree.updateReorder.apply(tree, [_this].concat(args));
        };

        meta.endReorder = function () {
          for (var _len6 = arguments.length, args = new Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
            args[_key6] = arguments[_key6];
          }

          return tree.endReorder.apply(tree, [_this].concat(args));
        }; // Changes to the value directly should properly update all computeds on this
        // node, but we need to manually propagate changes upwards to notify any other
        // watchers


        this._notifyMaxChildDepth = function () {
          return (0, _ember.notifyPropertyChange)(parent, 'maxChildDepth');
        };

        this._notifyLeaves = function () {
          return (0, _ember.notifyPropertyChange)(parent, 'leaves');
        };

        (0, _observer.addObserver)(this, 'maxChildDepth', this._notifyMaxChildDepth);
        (0, _observer.addObserver)(this, 'leaves.[]', this._notifyLeaves);
      }
    },
    destroy: function destroy() {
      this.cleanSubcolumnNodes();

      this._super.apply(this, arguments);
    },

    /**
      Fully destroys the child nodes in the event that they change or that this
      node is destroyed. If children are not destroyed, they will leak memory due
      to dangling references in Ember Meta.
    */
    cleanSubcolumnNodes: function cleanSubcolumnNodes() {
      if (this._subcolumnNodes !== null) {
        this._subcolumnNodes.forEach(function (n) {
          return n.destroy();
        });

        this._subcolumnNodes = null;
      }
    },
    subcolumnNodes: (0, _object.computed)('column.subcolumns.[]', 'tree.widthConstraint', function () {
      this.cleanSubcolumnNodes();

      if ((0, _object.get)(this, 'isLeaf')) {
        return;
      }

      var tree = (0, _object.get)(this, 'tree');
      var parent = this;
      this._subcolumnNodes = (0, _array.A)((0, _object.get)(this, 'column.subcolumns').map(function (column) {
        return ColumnTreeNode.create({
          column: column,
          tree: tree,
          parent: parent
        });
      }));
      var isRoot = (0, _object.get)(this, 'isRoot');
      var isSlackModeEnabled = (0, _object.get)(tree, 'isSlackModeEnabled');

      if (isRoot && isSlackModeEnabled) {
        var slackColumnNode = ColumnTreeNode.create({
          column: {
            isResizable: false,
            isReorderable: false,
            minWidth: 0,
            width: 0
          },
          tree: tree,
          parent: parent,
          isSlack: true
        });

        this._subcolumnNodes.push(slackColumnNode);
      }

      return this._subcolumnNodes;
    }),
    isLeaf: (0, _object.computed)('column.subcolumns.[]', 'isRoot', function () {
      var subcolumns = (0, _object.get)(this, 'column.subcolumns');

      if ((0, _object.get)(this, 'isRoot')) {
        return false;
      }

      return !subcolumns || (0, _object.get)(subcolumns, 'length') === 0;
    }),
    isSortable: (0, _object.computed)('column.isSortable', 'tree.enableSort', function () {
      var enableSort = (0, _object.get)(this, 'tree.enableSort');
      var valuePath = (0, _object.get)(this, 'column.valuePath');
      var isSortable = (0, _object.get)(this, 'column.isSortable');
      var isLeaf = (0, _object.get)(this, 'isLeaf');
      return isLeaf === true && enableSort !== false && isSortable !== false && typeof valuePath === 'string';
    }),
    isReorderable: (0, _object.computed)('column.isReorderable', 'tree.enableReorder', function () {
      var enableReorder = (0, _object.get)(this, 'tree.enableReorder');
      var isReorderable = (0, _object.get)(this, 'column.isReorderable');
      return enableReorder !== false && isReorderable !== false;
    }),
    isResizable: (0, _object.computed)('column.isResizable', 'tree.enableResize', function () {
      var isLeaf = (0, _object.get)(this, 'isLeaf');

      if (isLeaf) {
        var enableResize = (0, _object.get)(this, 'tree.enableResize');
        var isResizable = (0, _object.get)(this, 'column.isResizable');
        return enableResize !== false && isResizable !== false;
      } else {
        var subcolumns = (0, _object.get)(this, 'subcolumnNodes');
        return subcolumns.some(function (s) {
          return (0, _object.get)(s, 'isResizable');
        });
      }
    }),
    isFixed: (0, _object.computed)('parent.{isFixed,isRoot}', 'column.isFixed', function () {
      if ((0, _object.get)(this, 'parent.isRoot')) {
        return (0, _object.get)(this, 'column.isFixed');
      }

      return (0, _object.get)(this, 'parent.isFixed');
    }),
    depth: (0, _object.computed)('parent.depth', function () {
      if ((0, _object.get)(this, 'parent')) {
        return (0, _object.get)(this, 'parent.depth') + 1;
      }

      return 0;
    }),
    maxChildDepth: (0, _object.computed)('isLeaf', 'subcolumns.@each.depth', function () {
      if ((0, _object.get)(this, 'isLeaf')) {
        return (0, _object.get)(this, 'depth');
      }

      return Math.max.apply(Math, _toConsumableArray((0, _object.get)(this, 'subcolumnNodes').map(function (s) {
        return (0, _object.get)(s, 'maxChildDepth');
      })));
    }),
    leaves: (0, _object.computed)('isLeaf', 'subcolumnNodes.{[],@each.leaves}', function () {
      if ((0, _object.get)(this, 'isLeaf')) {
        return [this];
      }

      return (0, _object.get)(this, 'subcolumnNodes').reduce(function (leaves, subcolumn) {
        leaves.push.apply(leaves, _toConsumableArray((0, _object.get)(subcolumn, 'leaves')));
        return leaves;
      }, (0, _array.A)());
    }),
    minWidth: (0, _object.computed)('column.minWidth', function () {
      if ((0, _object.get)(this, 'isLeaf')) {
        var columnMinWidth = (0, _object.get)(this, 'column.minWidth');
        return typeof columnMinWidth === 'number' ? columnMinWidth : DEFAULT_MIN_WIDTH;
      }

      return (0, _object.get)(this, 'subcolumnNodes').reduce(function (sum, subcolumn) {
        var subcolumnMinWidth = (0, _object.get)(subcolumn, 'minWidth');
        return sum + subcolumnMinWidth;
      }, 0);
    }),
    maxWidth: (0, _object.computed)('column.maxWidth', function () {
      if ((0, _object.get)(this, 'isLeaf')) {
        var columnMaxWidth = (0, _object.get)(this, 'column.maxWidth');
        return typeof columnMaxWidth === 'number' ? columnMaxWidth : DEFAULT_MAX_WIDTH;
      }

      return (0, _object.get)(this, 'subcolumnNodes').reduce(function (sum, subcolumn) {
        var subcolumnMaxWidth = (0, _object.get)(subcolumn, 'maxWidth');
        return sum + subcolumnMaxWidth;
      }, 0);
    }),
    width: (0, _object.computed)('isLeaf', 'subcolumnNodes.@each.width', 'column.width', {
      get: function get() {
        if ((0, _object.get)(this, 'isLeaf')) {
          var column = (0, _object.get)(this, 'column');
          var columnWidth = (0, _object.get)(column, 'width');

          if (typeof columnWidth === 'number') {
            return columnWidth;
          } else {
            var meta = (0, _object.get)(this, 'tree.columnMetaCache').get(column);
            return (0, _object.get)(meta, '_width');
          }
        }

        return (0, _object.get)(this, 'subcolumnNodes').reduce(function (sum, subcolumn) {
          var subcolumnWidth = (0, _object.get)(subcolumn, 'width');
          return sum + subcolumnWidth;
        }, 0);
      },
      set: function set(key, newWidth) {
        var oldWidth = (0, _object.get)(this, 'width');
        var isResizable = (0, _object.get)(this, 'isResizable');
        var isSlack = (0, _object.get)(this, 'isSlack');

        if (!isResizable && !isSlack) {
          return oldWidth;
        }

        var delta = newWidth - oldWidth;
        var minWidth = (0, _object.get)(this, 'minWidth');
        var maxWidth = (0, _object.get)(this, 'maxWidth');
        delta = Math.max(Math.min(oldWidth + delta, maxWidth), minWidth) - oldWidth;

        if (delta === 0) {
          return oldWidth;
        }

        if ((0, _object.get)(this, 'isLeaf')) {
          var column = (0, _object.get)(this, 'column');
          var columnWidth = (0, _object.get)(column, 'width');
          var width = oldWidth + delta;

          if (typeof columnWidth === 'number') {
            (0, _object.set)(column, 'width', width);
          } else {
            var meta = (0, _object.get)(this, 'tree.columnMetaCache').get(column);
            (0, _object.set)(meta, '_width', width);
          }

          return width;
        } else {
          var subcolumns = (0, _object.get)(this, 'subcolumnNodes'); // Delta can only be rendered at a pixel level of precision in tables in
          // some browsers, so we round and distribute the remainder as well. We also
          // don't know when we may hit a constraint (e.g. minWidth) so we have to do
          // this repeatedly. We take the largest chunk we can and try to fit it into
          // each piece in a loop.
          // We distribute chunks to the columns starting from the column with the
          // smallest width to the column with the largest width.

          var sortedSubcolumns = subcolumns.sortBy('width').filter(function (n) {
            return (0, _object.get)(n, 'isResizable');
          }).reverse();
          var loopCount = 0;
          var prevDelta = 0;
          delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta);

          while (delta !== 0) {
            var deltaChunks = divideRounded(delta, sortedSubcolumns.length);

            for (var i = 0; i < deltaChunks.length; i++) {
              var subcolumn = sortedSubcolumns[i];
              var deltaChunk = deltaChunks[i];

              var _oldWidth = (0, _object.get)(subcolumn, 'width');

              var targetWidth = _oldWidth + deltaChunk;
              (0, _object.set)(subcolumn, 'width', targetWidth);

              var _newWidth = (0, _object.get)(subcolumn, 'width'); // subtract the amount that changed, if any


              delta -= _newWidth - _oldWidth;

              if (delta === 0) {
                break;
              }
            }

            delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta); // If we weren't able to change the delta at all, then we hit a hard
            // barrier. This can happen when a table has too many columns to size
            // down, for instance.

            if (prevDelta === delta) {
              break;
            }

            prevDelta = delta;
            loopCount++;

            if (loopCount > LOOP_COUNT_GUARD) {
              throw new Error('loop count exceeded guard while distributing width');
            }
          }

          return (0, _object.get)(this, 'width');
        }
      }
    }),
    contentWidth: (0, _object.computed)('subcolumnNodes.@each.{isSlack,width}', function () {
      return this.get('subcolumnNodes').reduce(function (sum, column) {
        return column.get('isSlack') ? sum : sum + column.get('width');
      }, 0);
    }),
    offsetIndex: (0, _object.computed)('parent.{offsetIndex,subcolumnNodes.[]}', function () {
      var parent = (0, _object.get)(this, 'parent');

      if (!parent) {
        return 0;
      }

      var subcolumns = (0, _object.get)(parent, 'subcolumnNodes');
      var offsetIndex = (0, _object.get)(parent, 'offsetIndex');

      var _iterator = _createForOfIteratorHelper(subcolumns),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var column = _step.value;

          if (column === this) {
            break;
          }

          offsetIndex += 1;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return offsetIndex;
    }),
    offsetLeft: (0, _object.computed)('parent.{offsetLeft,width}', function () {
      var parent = (0, _object.get)(this, 'parent');

      if (!parent) {
        return 0;
      }

      var subcolumns = (0, _object.get)(parent, 'subcolumnNodes');
      var offsetLeft = (0, _object.get)(parent, 'offsetLeft');

      var _iterator2 = _createForOfIteratorHelper(subcolumns),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var column = _step2.value;

          if (column === this) {
            break;
          }

          offsetLeft += (0, _object.get)(column, 'width');
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return offsetLeft;
    }),
    offsetRight: (0, _object.computed)('parent.{offsetRight,width}', function () {
      var parent = (0, _object.get)(this, 'parent');

      if (!parent) {
        return 0;
      }

      var subcolumns = (0, _object.get)(parent, 'subcolumnNodes').slice().reverse();
      var offsetRight = (0, _object.get)(parent, 'offsetRight');

      var _iterator3 = _createForOfIteratorHelper(subcolumns),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var column = _step3.value;

          if (column === this) {
            break;
          }

          offsetRight += (0, _object.get)(column, 'width');
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return offsetRight;
    }),

    /**
     * Value is `true` if any of the following are true:
     *
     * 1) this is the slack leaf and has non-zero width
     * 2) this is the rightmost leaf and there is no slack leaf
     * 3) this is the rightmost leaf and the slack leaf has zero width
     *
     * Use this to style the rightmost column.
     */
    isLastRendered: (0, _object.computed)('tree.root.leaves.length', 'tree.root.leaves.lastObject.{isSlack,width}', 'offsetIndex', 'isLeaf', 'isSlack', 'width', function () {
      var isLeaf = (0, _object.get)(this, 'isLeaf');

      if (!isLeaf) {
        return false;
      }

      var tree = (0, _object.get)(this, 'tree');
      var leaves = (0, _object.get)(tree, 'root.leaves');
      var lastLeaf = (0, _object.get)(leaves, 'lastObject'); // calculate index from the right

      var offsetIndex = (0, _object.get)(this, 'offsetIndex');
      var rightOffsetIndex = leaves.length - offsetIndex - 1;

      if (rightOffsetIndex === 0) {
        var isSlack = (0, _object.get)(this, 'isSlack');
        var width = (0, _object.get)(this, 'width');
        return !isSlack || width > 0;
      } else if (rightOffsetIndex === 1 && (0, _object.get)(lastLeaf, 'isSlack')) {
        return (0, _object.get)(lastLeaf, 'width') === 0;
      }

      return false;
    }),
    registerElement: function registerElement(element) {
      this.element = element;
    }
  });

  var _default = _object.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.token = new _emberRafScheduler.Token();
      this._sortColumnsByFixed = this.sortColumnsByFixed.bind(this);
      this._ensureWidthConstraint = this.ensureWidthConstraint.bind(this);
      (0, _observer.addObserver)(this, 'columns.@each.isFixed', this._sortColumnsByFixed);
      (0, _observer.addObserver)(this, 'widthConstraint', this._ensureWidthConstraint);
    },
    destroy: function destroy() {
      this.token.cancel();
      (0, _object.get)(this, 'root').destroy();
      (0, _observer.removeObserver)(this, 'columns.@each.isFixed', this._sortColumnsByFixed);
      (0, _observer.removeObserver)(this, 'widthConstraint', this._ensureWidthConstraint);

      this._super.apply(this, arguments);
    },
    root: (0, _object.computed)('columns', function () {
      var columns = (0, _object.get)(this, 'columns');
      return ColumnTreeNode.create({
        column: {
          subcolumns: columns
        },
        tree: this
      });
    }),
    rows: (0, _object.computed)('root.{maxChildDepth,leaves.[]}', function () {
      var rows = (0, _array.A)();
      var root = (0, _object.get)(this, 'root');
      var maxDepth = (0, _object.get)(root, 'maxChildDepth');
      var previousLevel = [root];

      for (var i = 0; i < maxDepth; i++) {
        var currentLevel = previousLevel.reduce(function (children, node) {
          if (!(0, _object.get)(node, 'isLeaf')) {
            children.push.apply(children, _toConsumableArray((0, _object.get)(node, 'subcolumnNodes')));
          }

          return children;
        }, []);
        var columns = currentLevel.map(function (node) {
          return (0, _object.get)(node, 'column');
        });
        rows.pushObject((0, _array.A)(columns));
        previousLevel = currentLevel;
      }

      return rows;
    }),
    leaves: (0, _object.computed)('root.leaves.[]', function () {
      return (0, _array.A)((0, _object.get)(this, 'root.leaves').map(function (n) {
        return n.column;
      }));
    }),
    leftFixedNodes: (0, _object.computed)('root.subcolumnNodes.@each.isFixed', function () {
      return (0, _object.get)(this, 'root.subcolumnNodes').filterBy('isFixed', 'left');
    }),
    rightFixedNodes: (0, _object.computed)('root.subcolumnNodes.@each.isFixed', function () {
      return (0, _object.get)(this, 'root.subcolumnNodes').filterBy('isFixed', 'right');
    }),
    unfixedNodes: (0, _object.computed)('root.subcolumnNodes.@each.isFixed', function () {
      return (0, _object.get)(this, 'root.subcolumnNodes').filter(function (s) {
        return !(0, _object.get)(s, 'isFixed');
      });
    }),
    scrollBounds: (0, _object.computed)('leftFixedNodes.@each.width', 'rightFixedNodes.@each.width', function () {
      var _getInnerClientRect = (0, _element.getInnerClientRect)(this.container),
          containerLeft = _getInnerClientRect.left,
          containerRight = _getInnerClientRect.right;

      containerLeft += (0, _object.get)(this, 'leftFixedNodes').reduce(function (sum, n) {
        return sum + (0, _object.get)(n, 'width');
      }, 0);
      containerRight -= (0, _object.get)(this, 'rightFixedNodes').reduce(function (sum, n) {
        return sum + (0, _object.get)(n, 'width');
      }, 0);
      return {
        containerLeft: containerLeft,
        containerRight: containerRight
      };
    }),
    isSlackModeEnabled: (0, _object.computed)('widthConstraint', function () {
      var widthConstraint = (0, _object.get)(this, 'widthConstraint');
      return widthConstraint === WIDTH_CONSTRAINT.EQ_CONTAINER_SLACK || widthConstraint === WIDTH_CONSTRAINT.GTE_CONTAINER_SLACK;
    }),
    sortColumnsByFixed: function sortColumnsByFixed() {
      // disable observer
      if (this._isSorting) {
        return;
      }

      this._isSorting = true;
      var columns = (0, _object.get)(this, 'columns');
      var sorted = (0, _sort.mergeSort)(columns, function (a, b) {
        var aIsFixed = (0, _object.get)(a, 'isFixed');
        var bIsFixed = (0, _object.get)(b, 'isFixed');
        var aValue = aIsFixed === 'left' ? -1 : aIsFixed === 'right' ? 1 : 0;
        var bValue = bIsFixed === 'left' ? -1 : bIsFixed === 'right' ? 1 : 0;
        return aValue - bValue;
      });
      var alreadySorted = true;

      for (var i = 0; i < columns.length; i++) {
        if (sorted[i] !== columns[i]) {
          alreadySorted = false;
          break;
        }
      }

      if (!alreadySorted) {
        _array2.splice.apply(void 0, [columns, 0, sorted.length].concat(_toConsumableArray(sorted)));
      }

      this._isSorting = false;
    },

    /**
      Performs initial sizing of the table columns according to tree's
      `initialFillMode` property, then attempts to satisfy width constraint.
       In `eq-container-slack` and `gte-container-slack` width contraint modes,
      this allows a default layout to be applied before slack is allocated.
    */
    performInitialLayout: function performInitialLayout() {
      if (!this.container) {
        return;
      }

      var leaves = (0, _object.get)(this, 'root.leaves'); // ensures that min and max widths are respected _before_ `applyFillMode()`
      // decides if the width constraint has been violated

      leaves.forEach(function (leaf) {
        var width = (0, _object.get)(leaf, 'width');
        var minWidth = (0, _object.get)(leaf, 'minWidth');
        var maxWidth = (0, _object.get)(leaf, 'maxWidth');
        var newWidth = Math.min(Math.max(width, minWidth), maxWidth);
        (0, _object.set)(leaf, 'width', newWidth);
      });
      var isSlackModeEnabled = (0, _object.get)(this, 'isSlackModeEnabled');
      var initialFillMode = (0, _object.get)(this, 'initialFillMode');

      if (isSlackModeEnabled && initialFillMode) {
        this.applyFillMode(initialFillMode);
      }

      this.ensureWidthConstraint();
    },

    /**
      Allocates excess whitespace to slack column (if present), then applies
      tree's `fillMode` in attempt to satisfy its `widthConstraint`.
     */
    ensureWidthConstraint: function ensureWidthConstraint() {
      if (!this.container) {
        return;
      }

      var isSlackModeEnabled = (0, _object.get)(this, 'isSlackModeEnabled');

      if (isSlackModeEnabled) {
        this.updateSlackColumn();
      }

      this.applyFillMode();
    },

    /**
      Resizes the slack column to fill excess whitespace in the container. If
      table columns exceed the width of the container, the slack column is set to
      a width of zero.
       The slack column is only present when the `widthConstraint` property is set
      to `eq-container-slack` or `gte-container-slack`.
    */
    updateSlackColumn: function updateSlackColumn() {
      var slackColumn = (0, _object.get)(this, 'root.subcolumnNodes').findBy('isSlack');

      if (slackColumn) {
        var containerWidth = this.getContainerWidth();
        var contentWidth = (0, _object.get)(this, 'root.contentWidth');
        var width = Math.max(containerWidth - contentWidth, 0);
        slackColumn.set('width', width);
      }
    },

    /**
      Attempts to satisfy tree's width constraint by resizing columns according
      to the specifid `fillMode`. If no `fillMode` is specified, the tree's
      own `fillMode` property will be used.
       @param {String} fillMode
     */
    applyFillMode: function applyFillMode(fillMode) {
      fillMode = fillMode || (0, _object.get)(this, 'fillMode');
      var widthConstraint = (0, _object.get)(this, 'widthConstraint');
      var containerWidth = this.getContainerWidth();
      var contentWidth = (0, _object.get)(this, 'root.contentWidth');
      var delta = containerWidth - contentWidth;

      if (widthConstraint === WIDTH_CONSTRAINT.EQ_CONTAINER && delta !== 0 || widthConstraint === WIDTH_CONSTRAINT.EQ_CONTAINER_SLACK && delta !== 0 || widthConstraint === WIDTH_CONSTRAINT.LTE_CONTAINER && delta < 0 || widthConstraint === WIDTH_CONSTRAINT.GTE_CONTAINER && delta > 0 || widthConstraint === WIDTH_CONSTRAINT.GTE_CONTAINER_SLACK && delta > 0) {
        if (fillMode === FILL_MODE.EQUAL_COLUMN) {
          (0, _object.set)(this, 'root.width', containerWidth);
        } else if (fillMode === FILL_MODE.FIRST_COLUMN) {
          this.resizeColumn(0, delta);
        } else if (fillMode === FILL_MODE.LAST_COLUMN) {
          var isSlackModeEnabled = (0, _object.get)(this, 'isSlackModeEnabled');
          var columns = (0, _object.get)(this, 'root.subcolumnNodes');
          var lastColumnIndex = isSlackModeEnabled ? columns.length - 2 : columns.length - 1;
          this.resizeColumn(lastColumnIndex, delta);
        } else if (fillMode === FILL_MODE.NTH_COLUMN) {
          var fillColumnIndex = (0, _object.get)(this, 'fillColumnIndex');
          (true && !(!(0, _utils.isEmpty)(fillColumnIndex)) && (0, _debug.assert)("fillMode 'nth-column' must have a fillColumnIndex defined", !(0, _utils.isEmpty)(fillColumnIndex)));
          this.resizeColumn(fillColumnIndex, delta);
        }
      }
    },
    resizeColumn: function resizeColumn(index, delta) {
      var columns = (0, _object.get)(this, 'root.subcolumnNodes');
      var fillColumn = columns[index];
      (true && !(fillColumn) && (0, _debug.assert)("Invalid column index, ".concat(index, ", for a table with ").concat(columns.length, " columns"), fillColumn));
      var oldWidth = (0, _object.get)(fillColumn, 'width');
      (0, _object.set)(fillColumn, 'width', oldWidth + delta);
    },
    getContainerWidth: function getContainerWidth() {
      var containerWidthAdjustment = (0, _object.get)(this, 'containerWidthAdjustment') || 0;
      return (0, _element.getInnerClientRect)(this.container).width * this.scale + containerWidthAdjustment;
    },
    getReorderBounds: function getReorderBounds(node) {
      var parent = (0, _object.get)(node, 'parent');
      var scale = this.scale;
      var scrollLeft = this.container.scrollLeft;

      var _getInnerClientRect2 = (0, _element.getInnerClientRect)(this.container),
          containerLeft = _getInnerClientRect2.left;

      var leftBound, rightBound, nodes;

      if ((0, _object.get)(parent, 'isRoot')) {
        var isFixed = (0, _object.get)(node, 'isFixed');

        if (isFixed === 'left') {
          nodes = (0, _object.get)(this, 'leftFixedNodes');
        } else if (isFixed === 'right') {
          nodes = (0, _object.get)(this, 'rightFixedNodes');
        } else {
          nodes = (0, _object.get)(this, 'unfixedNodes');
        }
      } else {
        nodes = (0, _object.get)(node, 'parent.subcolumnNodes');
      }

      if (true
      /* DEBUG */
      ) {
        var firstReorderableFound = false;
        var lastReorderableFound = false;

        var _iterator4 = _createForOfIteratorHelper(nodes),
            _step4;

        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var _node = _step4.value;

            if (lastReorderableFound && (0, _object.get)(_node, 'isReorderable')) {
              (true && !(false) && (0, _debug.assert)('Non-reorderable columns may only be contiguous segments at the beginning or end of their section (i.e. node middle columns in a list).', false));
            } else if (!firstReorderableFound && (0, _object.get)(_node, 'isReorderable')) {
              firstReorderableFound = true;
            } else if (firstReorderableFound && !lastReorderableFound && !(0, _object.get)(_node, 'isReorderable')) {
              lastReorderableFound = true;
            }
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
      }

      var reorderableNodes = nodes.filter(function (n) {
        return (0, _object.get)(n, 'isReorderable');
      });
      var left = (0, _element.getOuterClientRect)(reorderableNodes[0].element).left;
      var right = (0, _element.getOuterClientRect)(reorderableNodes[reorderableNodes.length - 1].element).right;
      leftBound = (left - containerLeft) * scale + scrollLeft;
      rightBound = (right - containerLeft) * scale + scrollLeft;
      return {
        leftBound: leftBound,
        rightBound: rightBound
      };
    },
    registerContainer: function registerContainer(container) {
      this.container = container;
      this.scale = (0, _element.getScale)(container);
      (0, _object.get)(this, 'root').registerElement(container);

      _emberRafScheduler.scheduler.schedule('sync', this.ensureWidthConstraint.bind(this), this.token);
    },
    getClosestColumn: function getClosestColumn(column, left, isFixed) {
      // If the column is fixed, adjust finder method and offset by the scroll
      // position since the column will appear stationary
      if (isFixed === 'left') {
        left -= this.container.scrollLeft;
      } else if (isFixed === 'right') {
        left += this.container.scrollWidth;
        left -= this.container.scrollLeft;
        left -= (0, _element.getInnerClientRect)(this.container).width * this.scale;
      }

      var subcolumns = (0, _object.get)(column.parent, 'subcolumnNodes');

      var _iterator5 = _createForOfIteratorHelper(subcolumns),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var _column = _step5.value;
          var offset = (0, _object.get)(_column, 'offsetLeft');

          if (left < offset + (0, _object.get)(_column, 'width')) {
            return _column;
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }

      return subcolumns[subcolumns.length - 1];
    },
    getClosestColumnOffset: function getClosestColumnOffset(column, left, isFixed) {
      var closestColumn = this.getClosestColumn(column, left, isFixed);
      var offsetLeft = (0, _object.get)(closestColumn, 'offsetLeft'); // If the column is fixed, readjust the offset so that it's correct within
      // the container

      if (isFixed === 'left') {
        offsetLeft += this.container.scrollLeft;
      } else if (isFixed === 'right') {
        offsetLeft -= this.container.scrollWidth;
        offsetLeft += this.container.scrollLeft;
        offsetLeft += (0, _element.getInnerClientRect)(this.container).width * this.scale;
      }

      return offsetLeft;
    },
    insertAfterColumn: function insertAfterColumn(parent, after, insert) {
      if (insert === after) {
        return;
      }

      var subcolumns = (0, _object.get)(parent, 'column.subcolumns');
      var afterColumn = (0, _object.get)(after, 'column');
      var insertColumn = (0, _object.get)(insert, 'column');
      var afterIndex = subcolumns.indexOf(afterColumn);
      var insertIndex = subcolumns.indexOf(insertColumn);
      (0, _array2.move)(subcolumns, insertIndex, afterIndex);
      (0, _ember.notifyPropertyChange)(subcolumns, '[]');
    },
    startReorder: function startReorder(node, clientX) {
      this.clientX = clientX;
      var bounds = this.getReorderBounds(node);
      this._reorderMainIndicator = new _reorderIndicators.MainIndicator(this.container, node.element, bounds);
      this._reorderDropIndicator = new _reorderIndicators.DropIndicator(this.container, node.element, bounds);
      this.container.classList.add('is-reordering');
    },
    updateReorder: function updateReorder(node, clientX) {
      this.clientX = clientX;

      this._updateReorder(node);

      if (!(0, _object.get)(node, 'isFixed')) {
        this.updateScroll(node, true, true, this._updateReorder.bind(this));
      }
    },
    _updateReorder: function _updateReorder(node) {
      var scrollLeft = this.container.scrollLeft;
      var realContainerLeft = (0, _element.getInnerClientRect)(this.container).left * this.scale;
      var offset = this.clientX * this.scale - realContainerLeft + scrollLeft;
      var width = (0, _object.get)(node, 'width');
      var newLeft = offset - width / 2;
      this._reorderMainIndicator.left = newLeft;
      this._reorderDropIndicator.left = this.getClosestColumnOffset(node, offset, (0, _object.get)(node, 'isFixed'));
      this._reorderDropIndicator.width = (0, _object.get)(this.getClosestColumn(node, this._reorderDropIndicator.left, (0, _object.get)(node, 'isFixed')), 'width');
    },
    endReorder: function endReorder(node) {
      var _this$onReorder;

      var scrollLeft = this.container.scrollLeft;
      var realContainerLeft = (0, _element.getInnerClientRect)(this.container).left * this.scale;
      var offset = this.clientX * this.scale - realContainerLeft + scrollLeft;

      var _this$getReorderBound = this.getReorderBounds(node),
          leftBound = _this$getReorderBound.leftBound,
          rightBound = _this$getReorderBound.rightBound;

      offset = Math.max(leftBound, offset);
      offset = Math.min(rightBound - 1, offset);
      var closestColumn = this.getClosestColumn(node, offset, (0, _object.get)(node, 'isFixed'));
      this.insertAfterColumn(node.parent, closestColumn, node);

      this._reorderMainIndicator.destroy();

      this._reorderDropIndicator.destroy();

      this._reorderMainIndicator = null;
      this._reorderDropIndicator = null;

      if (this._nextUpdateScroll) {
        this._nextUpdateScroll.cancel();

        this._nextUpdateScroll = null;
      }

      this.container.classList.remove('is-reordering');
      (_this$onReorder = this.onReorder) === null || _this$onReorder === void 0 ? void 0 : _this$onReorder.call(this, (0, _object.get)(node, 'column'), (0, _object.get)(closestColumn, 'column'));
    },
    startResize: function startResize(node, clientX) {
      this.clientX = clientX;
    },
    updateResize: function updateResize(node, clientX) {
      var delta = Math.floor((0, _object.get)(node, 'isFixed') === 'right' ? (this.clientX - clientX) * this.scale : (clientX - this.clientX) * this.scale);
      this.clientX = clientX;

      if (Math.abs(delta) < 1) {
        return;
      } // Add the class after at least one update has occured


      this.container.classList.add('is-resizing');

      this._updateResize(node, delta);
    },
    _updateResize: function _updateResize(node, delta) {
      var resizeMode = (0, _object.get)(this, 'resizeMode');
      var oldWidth = (0, _object.get)(node, 'width');
      var minWidth = (0, _object.get)(node, 'minWidth');
      delta = Math.max(oldWidth + delta, minWidth) - oldWidth;

      if (resizeMode === RESIZE_MODE.FLUID) {
        var parent = (0, _object.get)(node, 'parent');
        var child = node;
        var sibling;

        while (parent && !sibling) {
          var siblings = (0, _object.get)(parent, 'subcolumnNodes');
          sibling = siblings[siblings.indexOf(child) + 1];
          child = parent;
          parent = (0, _object.get)(child, 'parent');
        }

        if (sibling) {
          var siblingOldWidth = (0, _object.get)(sibling, 'width');
          var siblingMinWidth = (0, _object.get)(sibling, 'minWidth');
          delta = -(Math.max(siblingOldWidth - delta, siblingMinWidth) - siblingOldWidth);
          (0, _object.set)(sibling, 'width', siblingOldWidth - delta);
        } else {
          delta = 0;
        }
      }

      var newWidth = oldWidth + delta;
      (0, _object.set)(node, 'width', newWidth);
      this.ensureWidthConstraint.call(this);
    },
    endResize: function endResize(node) {
      var _this$onResize;

      if (this._nextUpdateScroll) {
        this._nextUpdateScroll.cancel();

        this._nextUpdateScroll = null;
      }

      this.container.classList.remove('is-resizing');
      (_this$onResize = this.onResize) === null || _this$onResize === void 0 ? void 0 : _this$onResize.call(this, (0, _object.get)(node, 'column'));
    },
    updateScroll: function updateScroll(node, stopAtLeft, stopAtRight, callback) {
      var _this2 = this;

      if (this._nextUpdateScroll) {
        return;
      }

      this._nextUpdateScroll = _emberRafScheduler.scheduler.schedule('sync', function () {
        _this2._nextUpdateScroll = null;
        var container = _this2.container;
        var clientX = _this2.clientX;
        var _this2$container = _this2.container,
            scrollLeft = _this2$container.scrollLeft,
            scrollWidth = _this2$container.scrollWidth;

        var _get2 = (0, _object.get)(_this2, 'scrollBounds'),
            containerLeft = _get2.containerLeft,
            containerRight = _get2.containerRight;

        var containerWidth = (0, _element.getOuterClientRect)(_this2.container).width * _this2.scale;

        var distanceToLeft = Math.max(clientX - containerLeft, 2);
        var distanceToRight = Math.max(containerRight - clientX, 2);
        var canScrollLeft = !stopAtLeft || scrollLeft !== 0;
        var canScrollRight = !stopAtRight || scrollLeft < scrollWidth - containerWidth;

        if (distanceToLeft <= SCROLL_THRESHOLD && canScrollLeft || distanceToRight <= SCROLL_THRESHOLD && canScrollRight) {
          var delta;

          if (distanceToLeft <= SCROLL_THRESHOLD) {
            delta = -SCROLL_THRESHOLD / distanceToLeft;
          } else {
            delta = SCROLL_THRESHOLD / distanceToRight;
          }

          delta = Math.round(delta);
          container.scrollLeft += delta;

          _this2.updateScroll(node, stopAtLeft, stopAtRight, callback);

          callback(node, delta);
        }
      }, this.token);
    }
  });

  _exports.default = _default;
});