define("ember-cli-clipboard/helpers/is-clipboard-supported", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isClipboardSupported = _exports.default = void 0;
  var isClipboardSupported = window && window.ClipboardJS ? window.ClipboardJS.isSupported : function () {
    return false;
  };
  _exports.isClipboardSupported = isClipboardSupported;

  var _default = (0, _helper.helper)(isClipboardSupported);

  _exports.default = _default;
});