define("liquid-fire/transitions/explode", ["exports", "@ember/array", "@ember/object/internals", "liquid-fire"], function (_exports, _array, _internals, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = explode;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Explode is not, by itself, an animation. It exists to pull apart
  // other elements so that each of the pieces can be targeted by
  // animations.
  var deduplicateChildElementIds = function deduplicateChildElementIds(parentElem) {
    if (!parentElem) {
      return;
    }

    var parentEl = parentElem;

    if (parentEl.id) {
      parentEl.setAttribute('id', "".concat((0, _internals.guidFor)(parentEl), "-").concat(parentEl.id));
    }

    var childrenWithUniqueIds = parentEl.querySelectorAll('[id]');

    if (childrenWithUniqueIds.length) {
      var _iterator = _createForOfIteratorHelper(childrenWithUniqueIds),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var el = _step.value;
          el.setAttribute('id', "".concat((0, _internals.guidFor)(el), "-").concat(el.id));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  };

  function explode() {
    var _this = this;

    var seenElements = {};
    var sawBackgroundPiece = false;

    for (var _len = arguments.length, pieces = new Array(_len), _key = 0; _key < _len; _key++) {
      pieces[_key] = arguments[_key];
    }

    var promises = pieces.map(function (piece) {
      if (piece.matchBy) {
        return matchAndExplode(_this, piece, seenElements);
      } else if (piece.pick || piece.pickOld || piece.pickNew) {
        return explodePiece(_this, piece, seenElements);
      } else {
        sawBackgroundPiece = true;
        return runAnimation(_this, piece);
      }
    });

    if (!sawBackgroundPiece) {
      if (this.newElement) {
        this.newElement.style.visibility = '';
      }

      if (this.oldElement) {
        this.oldElement.style.visibility = 'hidden';
      }
    }

    return _liquidFire.Promise.all(promises);
  }

  function explodePiece(context, piece, seen) {
    var childContext = _objectSpread({}, context);

    var selectors = [piece.pickOld || piece.pick, piece.pickNew || piece.pick];
    var cleanupOld, cleanupNew;

    if (selectors[0] || selectors[1]) {
      cleanupOld = _explodePart(context, 'oldElement', childContext, selectors[0], seen);
      cleanupNew = _explodePart(context, 'newElement', childContext, selectors[1], seen);

      if (!cleanupOld && !cleanupNew) {
        return _liquidFire.Promise.resolve();
      }
    }

    return runAnimation(childContext, piece).finally(function () {
      if (cleanupOld) {
        cleanupOld();
      }

      if (cleanupNew) {
        cleanupNew();
      }
    });
  }

  function _explodePart(context, field, childContext, selector, seen) {
    var child, childOffset, width, height, newChild;
    var elt = context[field];
    childContext[field] = null;

    if (elt && selector) {
      child = _toConsumableArray(elt.querySelectorAll(selector)).filter(function (elm) {
        var guid = (0, _internals.guidFor)(elm);

        if (!seen[guid]) {
          seen[guid] = true;
          return true;
        }
      });

      if (child.length > 0) {
        child = child[0];
        childOffset = getOffset(child);
        width = child.offsetWidth;
        height = child.offsetHeight;
        newChild = child.cloneNode(true);
        deduplicateChildElementIds(newChild); // Hide the original element

        child.style.visibility = 'hidden'; // If the original element's parent was hidden, hide our clone
        // too.

        if (elt.style.visibility === 'hidden') {
          newChild.style.visibility = 'hidden';
        }

        elt.parentElement.append(newChild);
        newChild.style.width = "".concat(width, "px");
        newChild.style.height = "".concat(height, "px");
        var newParentOffset = getOffset(newChild.offsetParent);
        css(newChild, {
          position: 'absolute',
          top: childOffset.top - newParentOffset.top + 'px',
          left: childOffset.left - newParentOffset.left + 'px',
          margin: 0
        }); // Pass the clone to the next animation

        childContext[field] = newChild;
        return function cleanup() {
          newChild.remove();
          child.style.visibility = '';
        };
      }
    }
  }

  function getOffset(ele) {
    var _ele$getBoundingClien;

    var rect = (_ele$getBoundingClien = ele === null || ele === void 0 ? void 0 : ele.getBoundingClientRect()) !== null && _ele$getBoundingClien !== void 0 ? _ele$getBoundingClien : {
      top: 0,
      left: 0
    };
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX
    };
  }

  function css(element, styles) {
    for (var property in styles) {
      element.style[property] = styles[property];
    }
  }

  function animationFor(context, piece) {
    var name, args, func;

    if (!piece.use) {
      throw new Error("every argument to the 'explode' animation must include a followup animation to 'use'");
    }

    if ((0, _array.isArray)(piece.use)) {
      name = piece.use[0];
      args = piece.use.slice(1);
    } else {
      name = piece.use;
      args = [];
    }

    if (typeof name === 'function') {
      func = name;
    } else {
      func = context.lookup(name);
    }

    return function () {
      return _liquidFire.Promise.resolve(func.apply(this, args));
    };
  }

  function runAnimation(context, piece) {
    return new _liquidFire.Promise(function (resolve, reject) {
      animationFor(context, piece).apply(context).then(resolve, reject);
    });
  }

  function matchAndExplode(context, piece, seen) {
    if (!context.oldElement || !context.newElement) {
      return _liquidFire.Promise.resolve();
    } // reduce the matchBy scope


    if (piece.pick) {
      context.oldElement = context.oldElement.querySelector(piece.pick);
      context.newElement = context.newElement.querySelector(piece.pick);
    }

    if (piece.pickOld) {
      context.oldElement = context.oldElement.querySelector(piece.pickOld);
    }

    if (piece.pickNew) {
      context.newElement = context.newElement.querySelector(piece.pickNew);
    } // use the fastest selector available


    var selector;

    if (piece.matchBy === 'id') {
      selector = function selector(attrValue) {
        return "#".concat(attrValue);
      };
    } else if (piece.matchBy === 'class') {
      selector = function selector(attrValue) {
        return ".".concat(attrValue);
      };
    } else {
      selector = function selector(attrValue) {
        var escapedAttrValue = attrValue.replace(/'/g, "\\'");
        return "[".concat(piece.matchBy, "='").concat(escapedAttrValue, "']");
      };
    }

    var hits = (0, _array.A)(_toConsumableArray(context.oldElement.querySelectorAll("[".concat(piece.matchBy, "]"))));
    return _liquidFire.Promise.all(hits.map(function (elt) {
      var attrValue = elt.getAttribute(piece.matchBy); // if there is no match for a particular item just skip it

      if (attrValue === '' || context.newElement.querySelectorAll(selector(attrValue)).length === 0) {
        return _liquidFire.Promise.resolve();
      }

      return explodePiece(context, {
        pick: selector(attrValue),
        use: piece.use
      }, seen);
    }));
  }
});