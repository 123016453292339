define("ember-local-storage/serializers/serializer", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    shouldSerializeHasMany: function shouldSerializeHasMany() {
      return true;
    },
    serializeBelongsTo: function serializeBelongsTo() {
      this._fixSerializeBelongsTo.apply(this, arguments);
    },
    serializeHasMany: function serializeHasMany() {
      this._fixSerializeHasMany.apply(this, arguments);
    },
    _fixSerializeBelongsTo: function _fixSerializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;

      if (this._canSerialize(key)) {
        var belongsTo = snapshot.belongsTo(key);
        var belongsToIsNotNew = belongsTo && belongsTo.record && belongsTo.record.get('id');

        if (belongsTo === null || belongsToIsNotNew) {
          json.relationships = json.relationships || {};
          var modelType = this.store.modelFor(snapshot.modelName);

          var payloadKey = this._getMappedKey(key, modelType);

          if (payloadKey === key) {
            payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
          }

          var data = null;

          if (belongsTo) {
            var payloadType = this.payloadKeyFromModelName(belongsTo.modelName);
            data = {
              type: payloadType,
              id: belongsTo.id
            };
          }

          json.relationships[payloadKey] = {
            data: data
          };
        }
      }
    },
    _fixSerializeHasMany: function _fixSerializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      if (this.shouldSerializeHasMany(snapshot, key, relationship)) {
        var hasMany = snapshot.hasMany(key);

        if (hasMany !== undefined) {
          json.relationships = json.relationships || {};
          var modelType = this.store.modelFor(snapshot.modelName);

          var payloadKey = this._getMappedKey(key, modelType);

          if (payloadKey === key && this.keyForRelationship) {
            payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
          } // only serialize has many relationships that are not new


          var nonNewHasMany = hasMany.filter(function (item) {
            return item.record && item.record.get('id');
          });
          var data = new Array(nonNewHasMany.length);

          for (var i = 0; i < nonNewHasMany.length; i++) {
            var item = hasMany[i];
            var payloadType = this.payloadKeyFromModelName(item.modelName);
            data[i] = {
              type: payloadType,
              id: item.id
            };
          }

          json.relationships[payloadKey] = {
            data: data
          };
        }
      }
    }
  });

  _exports.default = _default;
});