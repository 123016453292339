define("ember-table/components/ember-th/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Krp+/P2h",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"columnValue\"]],[30,0,[\"columnMeta\"]],[30,0,[\"rowMeta\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"columnValue\",\"name\"]]],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"columnMeta\"],[[30,0,[\"columnMeta\"]]]]]],[1,\"\\n\\n  \"],[1,[28,[35,4],null,[[\"columnMeta\"],[[30,0,[\"columnMeta\"]]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"ember-th/sort-indicator\",\"ember-th/resize-handle\"]]",
    "moduleName": "ember-table/components/ember-th/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});