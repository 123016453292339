define("ember-cli-pubnub/services/pubnub", ["exports", "@ember/error", "pubnub", "@ember/object"], function (_exports, _error, _pubnub, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = {
    create: function create() {
      var _get;

      var config = (_get = (0, _object.get)(this, 'config')) !== null && _get !== void 0 ? _get : {};

      if (!config || _typeof(config.pubnub) !== 'object') {
        throw new _error.default('Please set the `pubnub` property in the envrionment config');
      }

      return new _pubnub.default(config.pubnub);
    },
    config: null,
    isServiceFactory: true
  };
  _exports.default = _default;
});